@import "bootstrap-3.4.1";
@import "repute-custom";
@import "timeline";

h3.pretty-header {
   margin-top: 1.5em;
}
h2.pretty-header {
   margin-top: 1em;
}

.collapsed-container {
   padding: 1.5em;
}

.image-link {
    display: inline-block;
    padding: 2px;
    margin: 0 0.5rem 1rem 0.5rem;
    background-color: #fff;
    line-height: 0;
    border-radius: 4px;
    transition: background-color 0.5s ease-out;
 }
.image-link:hover {
  background-color: #4ae;
  transition: none;
 }

.bold {
   font-weight: bold;
}

.header-icon {
   margin-top: 10px;
   * {
		fill: #656565;
   }
}

.social-icon {
   svg {
      vertical-align: -0.35em;
   }
   * {
		fill: #fff;
   }
}

.icon {
   display: inline-block;
   width: 1em;
   height: 1em;
   overflow: visible;
   vertical-align: -.125em;
}

.home-service-icon > .icon {
   height: 40%;
   width: 40%;
}

.home-service-bolt-icon {
   @extend .home-service-icon;
}

.home-service-bolt-icon > .icon {
   height: 30%;
   width: 30%;
}

.icon-wrap-container {
   overflow: hidden; /* Clear floats */
}

.icon-wrap-128 {
   float: left;
   margin-right: 15px; /* Space between icon and text */
   margin-bottom: 15px;
   width: 128px; /* Adjust size as needed */
   height: 128px; /* Adjust size as needed */
}

.row-margin-from-prev {
   margin-top: 20px;
}

.row-large-margin-from-prev {
   margin-top: 40px;
}

.row-extra-large-margin-from-prev {
   margin-top: 80px;
}

.form-hidden {
   display: none;
}

@keyframes spinner {
   to { transform: rotate(360deg); }
}
.svg-spin-container > svg {
   * {
      fill: #fff;
   }
   animation: spinner 1s linear infinite;
}
